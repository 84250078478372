export const API = `https://math-app-backend.onrender.com/api/v1/users`;
// export const API = `http://127.0.0.1:5000/api/v1/users`;
// export const API = `http://127.0.0.1:5000/api/v1/users`;
// export const API = `http://192.168.0.108:5000/api/v1/users`;

export const config = () => {
  return {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("authToken"))}`,
    },
  };
};
